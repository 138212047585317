@media print {
  @page {
    size: landscape;
  }
}

#certificado-assban {
  font-family: Quicksand, sans-serif;
}

#certificado-assban .nome-aluno {
  color: black;
  display: block;
  font-size: 18px;
  font-weight: normal;
  padding-top: 316px;
  position: absolute;
  top: 0;
  left: 206px;
}

#certificado-assban .data-validade {
  color: black;
  display: block;
  font-size: 11px;
  left: 0;
  padding-left: 316px;
  padding-top: 364px;
  position: absolute;
  top: 0;
}

#certificado-assban .titulo-curso {
  color: black;
  display: block;
  font-size: 18px;
  font-weight: normal;
  left: 206px;
  position: absolute;
  top: 378px;
}

#certificado-assban .numero-certificado {
  color: black;
  display: block;
  font-size: 12px;
  left: 0;
  padding-left: 320px;
  padding-top: 468px;
  position: absolute;
  top: 0;
}

#certificado-assban .data-atual {
  color: black;
  display: block;
  font-size: 14px;
  left: 0;
  padding-left: 320px;
  padding-top: 511px;
  position: absolute;
  top: 0;
}

#certificado-assban .data-emissao {
  color: black;
  display: block;
  font-size: 12px;
  left: 0;
  padding-left: 613px;
  padding-top: 486px;
  position: absolute;
  top: 0;
}

#certificado-assban .data-validade {
  color: black;
  display: block;
  font-size: 12px;
  left: 0;
  padding-left: 871px;
  padding-top: 486px;
  position: absolute;
  top: 0;
}

#certificado {
  width: 1110px;
  height: auto;
  max-height: 100%;
  max-width: 1110px;
  height: 675px;
}
